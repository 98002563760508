import React from "react";
import { graphql } from 'gatsby';
import mobiscroll from "src/lib/mobiscroll-package/dist/js/mobiscroll.react.min";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";

const fetch = require('isomorphic-fetch')

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.createEvents = this.createEvents.bind(this);
    this.daysInMonth = this.daysInMonth.bind(this);

    this.state = {
      isLoaded: false,
      calendarEvents: [],
      targetEvent: {},
      pageInfo: []
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 640) {
        // window.onscroll = () => {
        //   window.history.pushState("", document.title, window.location.pathname + window.location.search);
        // }
        // window.history.pushState("", document.title, window.location.pathname + window.location.search);
        // document.getElementsByClassName("mbsc-event-list")[0].setAttribute("id", "mobile-event-list");
        // console.log(document.getElementById("mobile-event-list"));
      }
    }

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?slug=calendar-of-events`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ pageInfo: json });
        } else {
          console.log("Data did not load");
        }
      })
  }

  // Add event objects array to state so the events will display
  createEvents(json) {
    const events = json.events.map(event => {
      return ({
        start: new Date(event.start_date.replace(/-/g, "/")),
        end: new Date(event.end_date.replace(/-/g, "/")),
        id: event.id,
        text: `<a href="/port-info/event-details/?e=${event.id}">${event.title}</a>`
      })
    });

    this.setState({ calendarEvents: events });
  }

  // Return number of days in month for a specific year
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  // TODO: Go to event detail page when event selected from calendar
  onEventSelect = (event, inst) => {
    window.location = `/port-info/event-details/?e=${event.event.id}`;
  }

  // Load data for the month being viewed
  onPageLoading = (event, inst) => {
    const year = event.firstDay.getFullYear();
    const monthZeroBased = event.firstDay.getMonth(); // just in case we'll need the zero-based at some point
    const month = monthZeroBased + 1;

    fetch(`${process.env.GATSBY_API_URL}/wp-json/tribe/events/v1/events?start_date=${year}-${month}-1&end_date=${year}-${month}-${this.daysInMonth(month, year)}&per_page=100`)
      .then(response => response.json())
      .then(json => {
        this.setState({
          isLoaded: true
        })

        this.createEvents(json);
      })
  }

  // If we need it: when previous or next month arrow button activated
  // onPageChange = (event, inst) => {

  // }

  render() {
    const pageData = this.state.pageInfo[0];

    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero={true}>
              <SEO title={pageData.title.rendered} description="" />
              <Hero type="short" headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} mobileHeroBackgroundUrl={pageData.acf.mobile_background} subheading={pageData.acf.subheading} />

              <Breadcrumb categories={pageData.categories} pageTitle={pageData.title.rendered} />

              <div className="calendar gutter">
                <mobiscroll.Eventcalendar
                  lang="en"
                  theme="mobiscroll"
                  calendarHeight={700}
                  display="inline"
                  view={{
                    calendar: { labels: true },
                    eventList: { type: 'month', scrollable: true }
                  }}
                  onEventSelect={this.onEventSelect}
                  onPageChange={this.onPageChange}
                  data={this.state.calendarEvents}
                  onPageLoading={this.onPageLoading}
                />
              </div>
            </Layout>
            :
            null
        }
      </React.Fragment>
    );
  }
}

export default Calendar;

// export const pageQuery = graphql`
//   query($tagName: String = "dev_only_id_calendar") {
//     wordpressPage(tagNames: { in: [$tagName] }) {
//       id
//       slug
//       title
//       wordpress_parent
//       categories {
//         name
//         slug
//       }
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;